import React from 'react'
import classNames from 'classnames'

import * as style from './enterprisefooter.module.scss'
import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaYoutubeSquare,
  FaInstagramSquare
} from 'react-icons/fa'
import { BannersContainer } from '../banner_ads/BannersContainer'

const phoneLink = phone => {
  if (phone) {
    return `tel:+1${ phone.replace(/\D+/g, '') }`
  } else {
    return ''
  }
}

export const EnterpriseFooter = props => {
  const { footerItems } = props
  const renderWithItems = () => {
    if (!footerItems || footerItems.length === 0) {
      return
    }
    return (
      <footer className={classNames(style.footer, 'secondary')}>
        <div className="container">
          <div className={style.footerMain}>
            <BannersContainer banners={props.banners} placement='Footer' />
            <div className={style.footerItems}>
              { footerItems.map(i => (
                <div className={style.footerItem} key={i.name}>
                  <strong>{ i.name }</strong>
                  <ul className={style.footerItemList}>
                    { i.items.map(s => (
                      <li key={s.name}><a href={s.url}>{s.name}</a></li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className={style.bottomRow}>
              <div className={style.bottomCol}>
                { renderSocial(style.socialLeft) }
              </div>
              <div className={classNames(style.bottomCol, style.bottomColRight)}>
                { props.address }<br /> { address2 }<br />
                <a className={style.phone} href={phoneLink(props.phone)}>
                  { props.phone }
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(style.subFooterCenter, 'secondary')}>
          <div className="container">
            <div>
              <p className={style.subFooterP}>
                { props.name } © 2023. All rights reserved.
              </p>
            </div>
            { props.disclaimer && (
              <div className="col-12">
                { props.disclaimer }
              </div>
            )}
          </div>
        </div>
      </footer>

    )
  }

  const renderWithoutItems = () => {
    if (footerItems && footerItems.length >= 0) {
      return
    }
    return (

      <footer className={classNames(style.footer, 'secondary')}>
        <div className="container">
          <div className={style.footerMain}>
            <div>
              <div className={style.center}>
                { props.address } { address2 }<br />
                <a className={style.phone} href={phoneLink(props.phone)}>
                  { props.phone }
                </a>
              </div>
              <BannersContainer banners={props.banners} placement='Footer' />
              <div>
                <div className="col-sm-offset-4 col-sm-4">
                  { renderSocial(style.socials) }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(style.subFooterCenter, 'secondary')}>
          <div className="container">
            <div>
              <p className={style.subFooterP}>
                { props.name } © 2022. All rights reserved.
              </p>
            </div>
            { props.disclaimer && (
              <div className="col-12">
                { props.disclaimer }
              </div>
            )}
          </div>
        </div>
      </footer>

    )
  }
  const renderSocial = style => (
    <div className={style}>
      { props.facebook_url && (
        <a href={props.facebook_url} target="_blank">
          <FaFacebookSquare/>
        </a>
      )}
      { props.twitter_url && (
        <a href={props.twitter_url} target="_blank">
          <FaTwitterSquare/>
        </a>
      )}
      { props.instagram_url && (
        <a href={props.instagram_url} target="_blank">
          <FaInstagramSquare/>
        </a>
      )}
      { props.linkedin_url && (
        <a href={props.linkedin_url} target="_blank">
          <FaLinkedin/>
        </a>
      )}
      { props.youtube_url && (
        <a href={props.youtube_url} target="_blank">
          <FaYoutubeSquare/>
        </a>
      )}
    </div>
  )

  let address2 = ''
  if (props.city) {
    address2 = `${ props.city }, ${ props.state }, ${ props.zip }`
  }

  if (footerItems && footerItems.length >= 0) {
    return renderWithItems()
  } else {
    return renderWithoutItems()
  }
}
