import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { motion, useCycle } from 'framer-motion'

import { Link } from '../Button'
import { UserMenu } from './UserMenu'
import { DropDown } from '../utils/DropDown'

import { MenuToggle } from './menutoggle'
import { NavMenu } from './navmenu'
import * as style from './enterpriseheader.module.scss'

const navItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

export const EnterpriseHeader = ({
  user, logo, isLoading, isWhiteLabel,
  pricingTab,
  loginUrl = '/idp/login',
  loginText = 'Login',
  signupUrl = '/signup',
  signupText = 'Join the Network',
  allowSignup = true,
  allowBilling = true,
  navItems = [],
  containerClassName = 'container'
}) => {
  const [isOpen, toggleOpen] = useCycle(false, true)

  const renderNavs = () => {
    const userMenu = user && (
      <UserMenu user={user} isLoading={isLoading} allowBilling={allowBilling} />
    )
    const loginMenu = !user && (
      <Link href={loginUrl}>{loginText}</Link>
    )
    const joinMenu = !user && allowSignup && (
      <Link href={signupUrl}>{signupText}</Link>
    )
    const defaultItems = [
      { url: '/marketplace/', name: 'Member Benefits' }
    ]
    if (pricingTab) {
      defaultItems.push({ url: '/pricing/', name: 'Pricing' })
    }
    const menuItems = (navItems || []).length > 0
      ? navItems
      : defaultItems
    const buildItems = () => {
      return menuItems.map(i => {
        if (!i.items) {
          return (
            <motion.li key={i.name} className={classNames('nav-item', style.mobileNavLi)} variants={navItemVariants}>
              <a className="nav-link" href={i.url}>
                <span>{i.name}</span>
              </a>
            </motion.li>
          )
        } else {
          return (
            <motion.li key={i.name} className={style.mobileNavLi} variants={navItemVariants}>
              <DropDown
                key={`dropdown-${ i.name }`}
                alignRight={true}
                displayComponent={
                  <div className="nav-item dropdown simple-dropdown mega-parent-after">
                    <a className="nav-link dropdown-toggle" href={i.url}>
                      <span>{i.name}</span>
                    </a>
                  </div>
                }
                returnLink={
                  <div className="return-link d-block d-lg-none">
                    <a className="mb-20" href="#">{i.name}</a>
                  </div>
                }
                dropDownClass={'dropdown-menu1'}>
                <ul className="list-unstyled resources">
                  { i.items.map(s => (
                    <li key={s.name} className="mb-10">
                      <a href={s.url} className="dropdown-item">
                        <span>{s.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </DropDown>
            </motion.li>
          )
        }
      })
    }
    return (
      <div className={classNames('nav-list justify-content-end', style.navList)}>
        <ul className={classNames('navbar-nav mr-auto align-items-center', style.navbarNav)}>
          { buildItems() }
          <motion.li
            variants={navItemVariants}
            key="usermenu"
            className="nav-item nav-relative">
            { userMenu }
          </motion.li>
          <motion.li
            variants={navItemVariants}
            key="join"
            className="join-network nav-item">
            { joinMenu }
          </motion.li>
          <motion.li
            variants={navItemVariants}
            key="login"
            className="join-network nav-item">
            { loginMenu }
          </motion.li>
        </ul>
      </div>
    )
  }
  const navs = renderNavs()

  return (
    <header className={style.header} id="chalice-header">
      <nav className={classNames(style.navbar, 'navbar-expand-lg')}>
        <div className={classNames(containerClassName, style.navContainer)}>
          <div className={style.logoWrap}>{ logo }</div>
          <motion.div className={style.mobileNav} animate={isOpen ? 'open' : 'closed'}>
            <MenuToggle toggle={() => toggleOpen()} />
            <NavMenu>
              {navs}
            </NavMenu>
          </motion.div>
          <div className="nav-container">
            {navs}
          </div>
        </div>
      </nav>
    </header>
  )
}

EnterpriseHeader.propTypes = {
  logo: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  navItems: PropTypes.array,
  siteData: PropTypes.object,
  isWhiteLabel: PropTypes.bool,
  containerClassName: PropTypes.string
}

export default EnterpriseHeader
