import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const BannerAd = ({ banner }) => {
  if (banner?.type === 'Image') {
    return (
      <a href={banner.url}>
        <BannerImage src={banner.banner_image_url} alt='advertisement banner' />
      </a>
    )
  }

  if (banner?.type === 'HTML') {
    return (
      <div
        contentEditable="true"
        dangerouslySetInnerHTML={{ __html: banner.custom_html }}
      />
    )
  }

  return null
}

BannerAd.propTypes = {
  placement: PropTypes.oneOf(['Marketplace', 'Footer', 'Product'])
}

const BannerImage = styled.img`
  width: 100%;
  max-height: 500px;
`

export default BannerAd
