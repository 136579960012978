import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FaSpinner } from 'react-icons/fa'
import { GrMenu } from 'react-icons/gr'
import { BiSearch } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import axios from 'axios'

import { SearchBarModal } from './SearchBarModal'

import services from '../services'
import { Link } from '../Button'
import { UserMenu } from './UserMenu'
import { DropDown } from '../utils/DropDown'

import * as style from './chaliceheader.module.scss'
import styled, { keyframes } from 'styled-components'

const useModal = () => {
  const [visible, setVisible] = useState(false)
  function toggle () {
    setVisible(!visible)
  }
  return { toggle, visible }
}

const SearchBar = ({ mobile = false }) => {
  const [productList, setProductList] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const { visible, toggle } = useModal()

  const source = useRef(null)

  const onSearch = async e => {
    try {
      const value = e.target.value

      setSearch(value)
      setLoading(true)
      setProductList([])

      if (source.current) {
        source.current.cancel()
      }
      source.current = axios.CancelToken.source()

      const searchResults = await services.getV2('api/marketplace/search/', {
        params: { search: value },
        cancelToken: source.current.token
      })
      if (searchResults && searchResults.response) {
        setLoading(false)
        setProductList(searchResults.response)
      } else {
        // setLoading(false)
        // setProductList([])
      }
    } catch (err) {
      console.error(err)
    }
  }

  const searchResults = []

  if (search) {
    if (!productList || (productList && productList.length === 0)) {
      if (loading) {
        searchResults.push(
          <ProductBoxEmpty key='no-new-updates'>
            <Rotate />
          </ProductBoxEmpty>
        )
      } else {
        searchResults.push(
          <ProductBoxEmpty key='no-new-updates'>
            No Search Results
          </ProductBoxEmpty>
        )
      }
    } else {
      for (const i in productList) {
        searchResults.push(
          <div key={'search-result-' + i}>
            <ProductBox>
              <a href={`/marketplace/${ productList[i].slug }/`}>
                <ProductImageContainer>
                  <ProductImage src={productList[i].logo_url} />
                </ProductImageContainer>
                <ProductName>{productList[i].product_name}</ProductName>
                <ProductDescription>
                  {productList[i].description_short}
                </ProductDescription>
              </a>
            </ProductBox>
          </div>
        )
      }
    }
  }

  return (
    <SearchWrapper>
      <SearchButton onClick={toggle}>
        <BiSearch size={25} />
      </SearchButton>
      <SearchBarModal
        onSearch={onSearch}
        search={search}
        visible={visible}
        toggle={toggle}
        searchResults={searchResults}
        productList={productList}
        mobile={mobile}
      />
    </SearchWrapper>
  )
}

const SearchButton = styled(
  props => <button style={{
    border: 0,
    background: 'none',
    display: 'flex',
    alignItems: 'center'
  }} {...props} />
)`
  background: none;
  border: none;
  display: flex;
  align-items: center;
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`

const MembershipMenu = () => (
  <DropDown
    displayComponent={
      <li className='nav-item dropdown active mega-parent'>
        <a className='nav-link dropdown-toggle' href='#'>
          <span>Membership</span>
        </a>
      </li>
    }
    returnLink={
      <div className='return-link d-block d-lg-none'>
        <a className='mb-20' href='#'>
          Membership
        </a>
      </div>
    }
    dropDownClass={'dropdown-menu1 mega-menu1'}
  >
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <h4 className={style.megaHeader}>
              The Holy Grail for Business Owners Just Like You
            </h4>
            <p className={style.megaP}>
              What if there was a way to increase your earnings, decrease
              expenses and enhance the value of your business all in one place?
              That’s why we’ve built the Chalice Network—a membership platform
              that connects you with everything you need to compete, grow and
              thrive as an independent business owner.
            </p>
          </div>
          <div className='col-md-5'>
            <ul className='list-unstyled'>
              <li className='menu-card'>
                <a href='/marketplace/'>
                  <div className='card-title'>
                    <img src='/images/home/Marketplace.png' />
                    <span>Marketplace</span>
                    <span className='card-desc'>
                      Saving you money on benefits, technology, products and
                      services.
                    </span>
                  </div>
                </a>
              </li>
              {/*
              <li className="menu-card">
                <a href='/community/'>
                  <div className="card-title">
                    <img src="/images/home/Community Icon.png" />
                    <span>Community</span>
                    <span className="card-desc">
                      Connecting you with like-minded professionals in
                      your industry.
                    </span>
                  </div>
                </a>
              </li>
              */}
              <li className='menu-card'>
                <a href='/succession-link/'>
                  <div className='card-title'>
                    <img src='/images/home/MA_Platform.png' />
                    <span>M&A Platform</span>
                    <span className='card-desc'>
                      Communicate directly with buyers and sellers with our
                      platform.
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className='col-md-3'>
            <div className={style.megaMore}>
              <h5 className={style.megaH5}>More Benefits</h5>
              <DropdownDiv href='https://jobs.chalicenetwork.com'>
                Chalice Careers
              </DropdownDiv>
              {/*
              <DropdownDiv href="https://chalicefintechinstitute.com/">
                Fintech Institute
              </DropdownDiv>
              */}
              <DropdownDiv href='https://www.chalicepay.com'>
                Chalice Pay
              </DropdownDiv>
              <DropdownDiv href='/member-recognition/'>
                Member Recognition
              </DropdownDiv>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DropDown>
)

const DropdownDiv = ({ href, children }) => (
  <div>
    <a className='dropdown-item' href={href}>
      <span>{children}</span>
    </a>
  </div>
)

const DropdownItem = ({ href, children }) => (
  <li className='mb-10'>
    <a className='dropdown-item' href={href}>
      <span>{children}</span>
    </a>
  </li>
)

const ResourcesMenu = props => (
  <DropDown
    displayComponent={
      <li className='nav-item dropdown simple-dropdown mega-parent-after'>
        <a className='nav-link dropdown-toggle' href='#'>
          <span>Resources</span>
        </a>
      </li>
    }
    returnLink={
      <div className='return-link d-block d-lg-none'>
        <a className='mb-20' href='#'>
          Resources
        </a>
      </div>
    }
    dropDownClass='dropdown-menu1'
  >
    <ul className='list-unstyled resources'>
      <DropdownItem href='/partners/'>Partner Program</DropdownItem>
      <DropdownItem href='/roi-calculator/'>Savings Calculator</DropdownItem>
      <DropdownItem href='/blog/'>Blog</DropdownItem>
      <DropdownItem href='/news/'>News</DropdownItem>
      <DropdownItem href='/events/'>Events</DropdownItem>
      <DropdownItem href='/press/'>Press</DropdownItem>
    </ul>
  </DropDown>
)

export const ChaliceHeader = ({
  user,
  logo,
  isLoading,
  pricingTab,
  loginUrl = '/idp/login',
  signupUrl = '/signup',
  allowSignup = true,
  containerClassName = 'container'
}) => {
  const myRef = React.createRef()

  const renderNavs = () => {
    const userMenu = user && <UserMenu user={user} isLoading={isLoading} />
    const loginMenu = !user && <Link href={loginUrl}>Login</Link>
    const joinMenu = !user && <Link href={signupUrl}>90-Day Free Trial</Link>
    return (
      <div
        className={classNames('nav-list justify-content-end', style.navList)}
      >
        <ul className='navbar-nav mr-auto align-items-center'>
          <li className={'hidden-lg nav-item mb-8 ' + style.w100}>
            <a className='nav-link close-item'>
              <AiOutlineClose
                size={20}
                onClick={() => myRef.current.toggle()}
              />
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='/marketplace/'>
              <span>Marketplace</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pricing/">
              <span>Pricing</span>
            </a>
          </li>
          <ResourcesMenu />
          <li className='nav-item'>
            <a className='nav-link' href='/about/'>
              <span>About</span>
            </a>
          </li>

          <li key='usermenu' className='nav-item nav-relative'>
            {userMenu}
          </li>
          <li key='join' className='join-network nav-item'>
            {joinMenu}
          </li>
          <li key='login' className='join-network nav-item'>
            {loginMenu}
          </li>
          <li className="hidden-sm">
            <SearchBar />
          </li>
        </ul>
      </div>
    )
  }

  const navs = renderNavs()
  return (
    <header id="chalice-header" className="attop" style={{ boxShadow: "3px 0 15px 0 rgb(0 0 0 / 5%)" }}>
      <div className='site-search display-none'>
        <div className={containerClassName}>
          <div className='form-inline my-2 my-lg-0'>
            <input
              className='form-control mr-sm-2'
              type='text'
              placeholder='Search'
              aria-label='Search'
            ></input>
            <button className='btn btn-outline-success my-2 my-sm-0'>
              Search
            </button>
          </div>
        </div>
      </div>
      <nav className={classNames(style.navbar, 'navbar navbar-expand-lg')}>
        <div className={classNames(containerClassName, 'd-flex')}>
          <div className='logo-wrap'>{logo}</div>
          <div className='nav-button-container'>
            <div
              style={{
                marginTop: '5px',
                marginRight: '25px'
              }}
            >
              <SearchBar mobile={true} />
            </div>
            <DropDown displayComponent={<GrMenu size={20} />} ref={myRef}>
              {navs}
            </DropDown>
          </div>
          <div className='nav-container'>{navs}</div>
        </div>
      </nav>
    </header>
  )
}

ChaliceHeader.propTypes = {
  logo: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  siteData: PropTypes.object,
  containerClassName: PropTypes.string
}

const ProductBoxEmpty = styled.div`
  padding: 43px 10px;
  font-size: 1.4em;
  font-weight: 500;
  color: black;
  margin: 0 auto;
  background-color: white;
  width: 100%;
  svg {
    animation: spin 2s linear infinite;
  }
`
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

const Rotate = styled(FaSpinner)`
  animation: ${ spin } 2s linear infinite;
`

const ProductBox = styled.div`
  padding: 10px;
  background-color: #fff;
  img {
    max-width: 100%;
  }
  border-bottom: 1px #eee solid;
  a {
    padding: 10px;
  }
`

const ProductImageContainer = styled.div`
  background-color: #fff !important;
  text-align: center;
`

const ProductImage = styled.img`
  height: 72.5px;
`

const ProductName = styled.div`
  font-size: 1.4em;
  font-weight: 500;
  color: rgb(29, 41, 63);
  padding: 10px 0;
`

const ProductDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
`
