import * as React from 'react'
import { motion } from 'framer-motion'

import * as style from './enterpriseheader.module.scss'

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke={'#fff'}
    strokeLinecap="round"
    {...props}
  />
)

export const MenuToggle = ({ toggle }) => (
  <button onClick={toggle} className={style.toggleButton}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5', stroke: '#000' },
          open: { d: 'M 3 16.5 L 17 2.5', stroke: '#000' }
        }}
      />
      <Path
        opacity={1}
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1, stroke: '#000' },
          open: { opacity: 0, stroke: '#000' }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346', stroke: '#000' },
          open: { d: 'M 3 2.5 L 17 16.346', stroke: '#000' }
        }}
      />
    </svg>
  </button>
)
