import React from 'react'
import PropTypes from 'prop-types'
import { ChaliceHeader } from './header/ChaliceHeader'
import { EnterpriseHeader } from './header/EnterpriseHeader'

const Header = props => {
  if (props.isWhiteLabel) {
    return <EnterpriseHeader {...props} />
  } else {
    return <ChaliceHeader {...props} />
  }
}

Header.propTypes = {
  logo: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  navItems: PropTypes.array,
  siteData: PropTypes.object,
  isWhiteLabel: PropTypes.bool,
  containerClassName: PropTypes.string
}

export default Header
