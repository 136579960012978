// extracted by mini-css-extract-plugin
export var empty = "chaliceheader-module--empty--7b57a";
export var isHidden = "chaliceheader-module--isHidden--42bdb";
export var megaH5 = "chaliceheader-module--megaH5--ffa67";
export var megaHeader = "chaliceheader-module--megaHeader--2de0a";
export var megaMenu1 = "chaliceheader-module--mega-menu1--f4e77";
export var megaMore = "chaliceheader-module--megaMore--07a5d";
export var megaP = "chaliceheader-module--megaP--ca000";
export var navSearch = "chaliceheader-module--nav-search--9f44f";
export var none = "chaliceheader-module--none--680b2";
export var placeholder = "chaliceheader-module--placeholder--3a1ae";
export var productBox = "chaliceheader-module--productBox--bf43d";
export var productBoxEmpty = "chaliceheader-module--productBoxEmpty--27f51";
export var productDescription = "chaliceheader-module--productDescription--9c187";
export var productImg = "chaliceheader-module--productImg--188ba";
export var productImgContainer = "chaliceheader-module--productImgContainer--faa6b";
export var productName = "chaliceheader-module--productName--5e87e";
export var rotate = "chaliceheader-module--rotate--9a8d0";
export var searchContainer = "chaliceheader-module--searchContainer--b068f";
export var spin = "chaliceheader-module--spin--a05c5";
export var w100 = "chaliceheader-module--w100--ab4c7";