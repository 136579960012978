// extracted by mini-css-extract-plugin
export var btn = "chalicefooter-module--btn--d58b2";
export var btnBlueSmall = "chalicefooter-module--btnBlueSmall--a7e29";
export var btnBlueSolid = "chalicefooter-module--btnBlueSolid--fbc8b";
export var buttonAbsolute = "chalicefooter-module--buttonAbsolute--e443f";
export var flexCenter = "chalicefooter-module--flexCenter--8939b";
export var footer = "chalicefooter-module--footer--7a93b";
export var footerCta = "chalicefooter-module--footerCta--e9193";
export var footerMain = "chalicefooter-module--footerMain--ec0eb";
export var footerMainA = "chalicefooter-module--footerMainA--dc2bc";
export var footerMainH2 = "chalicefooter-module--footerMainH2--359ba";
export var footerMainLi = "chalicefooter-module--footerMainLi--b4420";
export var formGroup = "chalicefooter-module--formGroup--09756";
export var h3 = "chalicefooter-module--h3--fd65f";
export var p = "chalicefooter-module--p--02ced";
export var right = "chalicefooter-module--right--642b9";
export var socials = "chalicefooter-module--socials--05b00";
export var subFooter = "chalicefooter-module--subFooter--2e519";
export var subFooterP = "chalicefooter-module--subFooterP--aea91";