import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import * as style from './enterpriseheader.module.scss'

const backgroundVariants = {
  open: {
    opacity: 1,
    display: 'flex',
    transition: {
      stiffness: 400,
      restDelta: 40
    }
  },
  closed: {
    opacity: 0,
    transition: {
      stiffness: 400,
      damping: 40
    },
    transitionEnd: {
      display: 'none'
    },
  }
}

const navVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

export const NavMenu = ({ children }) => (
  <div key="navmenu">
    <motion.div variants={backgroundVariants} initial='closed' className={style.background}>
      <motion.div className={classNames('container', style.navigation)} variants={navVariants}>
        { children }
      </motion.div>
    </motion.div>
  </div>
)
