import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const deviceVariants = (desktop, mobile) => {
  if (typeof window !== 'undefined') {
    if (window.screen && window.screen.width < 990) {
      return mobile
    }
  }
  return desktop
}

const variants = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0.1,
      duration: 0.1
    },
    transitionEnd: {
      display: 'none'
    },
  },
  visible: {
    opacity: 1,
    display: 'block',
    transition: {
      duration: 0.1
    }
  },
}

const mobileVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.1
    },
    transitionEnd: {
      display: 'none'
    },
  },
  visible: {
    opacity: 1,
    display: 'block',
    transition: {
      duration: 0.1
    }
  },
}

class DropDown extends Component {
  state = {
    hide: true,
  }

  mouseOver = e => {
    if (typeof window !== 'undefined' && window.innerWidth >= 991) {
      this.setState({
        hide: false
      })
    }
  }

  mouseOut = e => {
    if (typeof window !== 'undefined' && window.innerWidth >= 991) {
      this.setState({
        hide: true
      })
    }
  }

  toggle = e => {
    this.setState({
      hide: !this.state.hide
    })
  }

  returnClicked = e => {
    this.setState({
      hide: true
    })
  }

  render () {
    return (
      <div
        className="nav-dropdown"
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}>
        <div className='nav-dropdown-display' onClick={this.toggle}>
          { this.props.displayComponent }
        </div>
        <motion.ul
          initial="hidden"
          variants={deviceVariants(variants, mobileVariants)}
          animate={ this.state.hide ? 'hidden' : 'visible' }
          className={this.props.dropDownClass}>
          <div onClick={this.returnClicked}>{ this.props.returnLink }</div>
          { this.props.children }
        </motion.ul>
      </div>
    )
  }
}

DropDown.propTypes = {
  displayComponent: PropTypes.object,
  returnLink: PropTypes.object,
  children: PropTypes.object,
  alignRight: PropTypes.bool,
  dropDownClass: PropTypes.string
}

export { DropDown }
