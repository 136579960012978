import React from 'react'
import PropTypes from 'prop-types'
import { Img } from 'react-image'

import { MembershipBadge } from './MembershipBadge'
import { DropDown } from '../utils/DropDown'
import { MdPersonOutline } from 'react-icons/md'

import * as style from './usermenu.module.scss'

const SUPER_ADMIN_ROLE = 2
const PORTAL_ALL_ACCESS_ROLE = 2
const ENTERPRISE_ADMIN_ROLE = 3

export const UserMenu = ({ isLoading, user, allowBilling = true }) => {
  const submenu = []
  if (user && !isLoading) {
    const isAdmin =
      user.role_id === SUPER_ADMIN_ROLE ||
      user.role_id === ENTERPRISE_ADMIN_ROLE
    const orgData = user.organization_data || {}
    const pricingTier = orgData.tier || 0
    submenu.push(
      <li className="mb-10 hidden-lg" key="dashboard">
        <a key="profile" href='/dashboard/' className="dropdown-item">
          <span>Dashboard</span>
        </a>
      </li>
    )
    submenu.push(
      <li className="mb-10 hidden-lg" key="integrations">
        <a key="profile" href='/integrations/' className="dropdown-item">
          <span>Integrations</span>
        </a>
      </li>
    )
    submenu.push(
      <li className="mb-10" key="profile">
        <a key="profile" href='/profile' className="dropdown-item">
          <span>
            My Profile &nbsp;&nbsp;<MembershipBadge tier={pricingTier} />
          </span>
        </a>
      </li>
    )
    if (user.role_id === SUPER_ADMIN_ROLE ||
      user.role_id === ENTERPRISE_ADMIN_ROLE) {
    } else if (pricingTier === 3 && user.can_access_billing) {
      submenu.push(
        <li className="mb-10" key="billing">
          <a key="subscription" href="/billing/" className="dropdown-item">
            <span>Billing</span>
          </a>
        </li>
      )
    } else if (user.can_access_billing && allowBilling) {
      submenu.push(
        <li className="mb-10" key="subscribe">
          <a key="subscription" href="/subscribe/" className="dropdown-item">
            <span>Upgrade</span>
          </a>
        </li>
      )
    }
    if (isAdmin) {
      submenu.push(
        <li className="mb-10" key="adminportal">
          <a href="/admin/dashboard/" className="dropdown-item">
            <span>Admin Portal</span>
          </a>
        </li>
      )
    }
    if (user.partner_id ||
      user.role_id === PORTAL_ALL_ACCESS_ROLE ||
      user.role_id === ENTERPRISE_ADMIN_ROLE) {
      submenu.push(
        <li className="mb-10" key="portal">
          <a href="/partner/portal/" className="dropdown-item">
            <span>Partner Portal</span>
          </a>
        </li>
      )
    }
    submenu.push(
      <li className="mb-10" key="logout">
        <a href="/idp/logout" className="dropdown-item change-pointer">
          <span>Sign Out</span>
        </a>
      </li>
    )

    return (
      <DropDown alignRight={true} displayComponent={
        <li className="nav-item nav-user-image dropdown simple-dropdown">
          <a href="#" className="nav-link-no-style dropdown-toggle">
            <div className={style.profileImg}>
              <MdPersonOutline />
            </div>
          </a>
        </li>
      }
      returnLink={
        <div className="return-link d-block d-lg-none">
          <a className="mb-20" href="#">User</a>
        </div>
      }
      dropDownClass='dropdown-menu1 nav-image-dropdown'>
        <ul className="list-unstyled resources">
          { submenu }
        </ul>
      </DropDown>
    )
  } else {
    return null
  }
}

UserMenu.propTypes = {
  isLoading: PropTypes.bool,
  user: PropTypes.object
}
