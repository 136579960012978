import React from 'react'
import PropTypes from 'prop-types'

import * as style from './sectionlabel.module.scss'

export const SectionLabel = ({ children }) => (
  <h2 className={style.sectionLabel}>
    { children }
  </h2>
)

SectionLabel.propTypes = {
  children: PropTypes.string
}
