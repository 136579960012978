import jwtDecode from 'jwt-decode'

import { MEMBERSHIP_TIER } from './constants'

class Store {
  session = null

  update () {
    const localStorage = typeof window !== 'undefined' && window.localStorage
    if (localStorage && localStorage.access_token) {
      this.session = jwtDecode(localStorage.access_token)
    } else {
      // this.session = jwtDecode('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NzM1LCJlbWFpbCI6InRpbUBzdWNjZXNzaW9ubGluay5jb20iLCJyb2xlX2lkIjoyLCJzdHJpcGVfY3VzdG9tZXJfaWQiOiIiLCJwcm9maWxlX2ltYWdlIjoiaW1hZ2VzL3Byb2ZpbGVfaW1hZ2VzL0dldFBlcnNvbmFQaG90by0xNTcxNjc2MjkxODUxLmpwZWciLCJmaXJzdF9uYW1lIjoiVGltIiwidGllciI6MywidGllcl9uYW1lIjoiVWx0aW1hdGUifQ.c5R9c_CQ_M-LRetKRcswTt7TVVE3Qpmbacq3Ed_M1XI')
      this.session = null
    }
  }

  tier = () => {
    let pricingTier = 0
    if (this.session) {
      const { activeSubscription } = this.session
      if (!activeSubscription) {
        pricingTier = 0
      } else if (activeSubscription) {
        pricingTier = MEMBERSHIP_TIER[activeSubscription.membership_plan_id]
      }
    }
    return pricingTier
  }
}

export default new Store()
