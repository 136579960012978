import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { StaticImage } from "gatsby-plugin-image"
import smoothscroll from 'smoothscroll-polyfill'
import { Header, Footer } from './style/'
import Store from '../components/store'

import '../styles/app.scss'

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

const Logo = props => (
  <Link to="/" className="logo-link">
    <StaticImage
      alt="Chalice Logo"
      layout="fullWidth"
      width="200px"
      src="../images/logo.png" />
  </Link>
)

export class GlobalWrapper extends Component {
  render () {
    Store.update()
    const { children } = this.props
    return (
      <div>
        <Header
          key="header"
          logo={<Logo />}
          user={Store.session}/>
        { children }
        <Footer key="footer" />
      </div>
    )
  }
}
