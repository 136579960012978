import axios from 'axios'

function hostMap () {
  if (typeof window === 'undefined') {
    return 'https://www.chalicenetwork.com'
  }
  const url = window.location.href
  if (url.match(/www.chalicenetwork/)) {
    return 'https://www.chalicenetwork.com/api-internal'
  } else if (url.match(/staging.chalicenetwork.com/)) {
    return 'https://staging.www.chalicenetwork.com/api-internal'
  } else if (url.match(/chalicefintech.local.com/) || url.match('localhost')) {
    return 'http://chalicefintech.local.com:8000/api-internal'
  } else {
    return 'https://www.chalicenetwork.com/api-internal'
  }
}

const baseUrlV2 = hostMap()
const localStorage = typeof window !== 'undefined' && window.localStorage
const config = {
  baseURL: baseUrlV2,
  withCredentials: false,
  responseType: 'json',

  transformResponse: [data => data],
  maxRedirects: 5, // default
  // maxContentLength: 200,
  validateStatus: status => {
    return status >= 200 && status < 500 // default
  },
  xsrfCookieName: 'XSRF-TOKEN', // default
  xsrfHeaderName: 'X-XSRF-TOKEN', // default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 0
}

const apiInstanceV2 = axios.create(config)
apiInstanceV2.defaults.headers.common['X-Enterprise-ID'] = 'chalicenetwork'
if (typeof window !== 'undefined') {
  apiInstanceV2.defaults.headers.common['X-Enterprise-Domain'] =
    `${ window.location.hostname }`
}

function getV2 (apiURL, options = {}, hostname = null) {
  if (localStorage.access_token) {
    apiInstanceV2.defaults.headers.common.Authorization =
      localStorage.access_token
  }
  if (hostname) {
    apiInstanceV2.defaults.headers.common['X-Enterprise-Domain'] = hostname
  }
  return (
    apiInstanceV2.get(apiURL, options).then(response => {
      return handleResponse(response)
    }).catch(error => {
      return handleError(error)
    })
  )
}

function handleResponse (response) {
  let resData = {}
  if (typeof response.data === 'object') {
    if (response.status === 404) {
      resData = {
        status: 'failure',
        statusCode: 404,
        message: response.statusText
      }
    } else {
      resData = response.data
    }
  } else {
    resData = JSON.parse(response.data)
  }
  return resData
}

function handleError (error) {
  if (error.message === 'Network Error') {
    return { status: 'failure', statusCode: 500, message: error.message }
  } else if (error.message && !error.status) {
    return { status: 'failure', statusCode: 503, message: error.message }
  } else if (error.response && error.response.data) {
    return error.response.data
  } else if (error.response) {
    return {
      status: 'failure',
      statusCode: error.response.status,
      message: error.message
    }
  } else if (error.request) {
    return {
      status: 'failure',
      statusCode: error.status,
      message: error.message
    }
  } else {
    return {
      status: 'failure',
      statusCode: error.status,
      message: error.message
    }
  }
}

export default {
  getV2
}
