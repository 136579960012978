import React from 'react'

import { EnterpriseFooter } from './footer/EnterpriseFooter'
import { ChaliceFooter } from './footer/ChaliceFooter'

export const Footer = props => {
  if (props.isWhiteLabel) {
    return <EnterpriseFooter {...props} />
  } else {
    return <ChaliceFooter {...props} />
  }
}
