import React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { RiCloseFill } from 'react-icons/ri'
import { BiSearch } from 'react-icons/bi'

const MotionDialogContent = DialogContent

export const SearchBarModal = ({
  visible,
  toggle,
  onSearch,
  search,
  searchResults,
  productList,
  mobile
}) => {
  return (
    <AnimatePresence>
      <DialogOverlay
        isOpen={visible}
        onDismiss={toggle}
        style={{ zIndex: 100002 }}>
        <DialogContentStyled
          aria-label="Modal"
          intial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: -100 }}
          mobile={mobile}
        >
          <InputWrapper mobile={mobile} visible={visible} className="container">
            <BiSearch size={30} />
            <input
              onChange={e => {
                onSearch(e)
              }}
              value={search}
              type="text"
              placeholder="Search"
            />
            <CloseButtonWrapper className="search-icon" onClick={() => toggle()}>
              <RiCloseFill size={30} />
            </CloseButtonWrapper>
          </InputWrapper>
          <SearchContainer
            isHidden={!search}
            isEmpty={productList.length === 0}
          >
            {searchResults}
          </SearchContainer>
        </DialogContentStyled>
      </DialogOverlay>
    </AnimatePresence>
  )
}

// STYLING

const DialogContentStyled = styled(MotionDialogContent)`
  position: absolute;
  top: 0;
  margin: 0;
  width: 100%;
  padding: 0;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  display: flex;
  justify-content: center;
`

const CloseButtonWrapper = styled(
  props => <button className="search-icon" {...props} />
)`
  background: none;
  border: none;
  display: flex;
  align-items: center;
`

const InputWrapper = styled.div`
  display: ${ props => props.visible ? 'flex' : 'none' };
  gap: 20px;
  align-items: center;
  padding: 30px 0;

  input {
    font-size: 22px;
    border: none;
    width: 100%;
  }

  input:focus {
    outline: none;
  }
`

const SearchContainer = styled.div`
  position: fixed;
  top: 89px;
  border-top: 1px solid #e8e8e8;
  color: black !important;
  overflow: auto;
  max-height: 70vh;
  width: 60%;

  @media only screen and (max-width: 990px) {
    width: 100%;
    border: none;
  }
`
