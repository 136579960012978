import React from 'react'
import PropTypes from 'prop-types'

import * as style from './dashlink.module.scss'

export const DashLink = ({ href, children }) => (
  <a href={ href } className={style.dashLink}>
    <span></span>{ children }
  </a>
)

export const DashLinkWhite = ({ href, children }) => (
  <a href={ href } className={style.dashLinkWhite}>
    <span></span>{ children }
  </a>
)

DashLink.propTypes = {
  children: PropTypes.object,
  href: PropTypes.string
}
