import React from 'react'

export const COMMUNITY_URL = 'https://community.chalicenetwork.com/saml/sso'
export const LOGIN_URL = `${ process.env.CHALICE_HOST }/idp/login/`
export const LOGOUT_URL = `${ process.env.CHALICE_HOST }/idp/logout/`
export const SIGNUP_URL = `${ process.env.CHALICE_HOST }/signup/`
export const SIGNUP_URL_FREE = `${ process.env.CHALICE_HOST }/signup/register?plan=0`
export const MARKETPLACE_URL = `${ process.env.CHALICE_HOST }/marketplace/`
export const ROI_CALCULATOR = `${ process.env.CHALICE_HOST }/roi-calculator/`
export const PROFILE_URL = `${ process.env.CHALICE_HOST }/profile/`
export const PROFILE_BILLING =
  `${ process.env.CHALICE_HOST }/profile/billing/subscription/`
export const SUBSCRIBE =
  `${ process.env.CHALICE_HOST }/subscribe`
export const DASHBOARD_URL = `${ process.env.CHALICE_HOST }/dashboard`
export const INTEGRATIONS_URL = `${ process.env.CHALICE_HOST }/integrations`
export const SNAPSHOT_URL = `${ process.env.CHALICE_HOST }/snapshot/`
export const ADMIN_URL =
  `${ process.env.CHALICE_HOST }/admin/dashboard`
export const PORTAL_URL = `${ process.env.CHALICE_HOST }/portal/`
export const MEMBERSHIP_TIER = {
  'free': 0,
  'cn-monthly-25': 1,
  'cn-yearly-240': 1,
  'cn-monthly-50': 2,
  'cn-yearly-480': 2,
  'cn-monthly-100': 3,
  'cn-yearly-960': 3
}
export const PRICING_TIERS = {
  0: 'Free',
  1: 'Essentials',
  2: 'Advantage',
  3: 'Ultimate'
}

export const KEITH = (
  <>
    <p>
      Keith is a Financial Services and FinTech Executive, Founder and Chief
      Executive Officer of San Diego-based FinTech Company Chalice
      Network, a member benefit organization for independent wealth advisors that
      delivers a fully integrated suite of technology tools, products, and services
      through a single sign-on shared services technology platform – Chalice
      Advisor Exchange™. He has three decades of executive leadership and financial
      services experience. Prior to founding Chalice Network he was
      Founder &amp; CEO of Innovation Equity Partners, CEO of Capital Guardian
      Securities and First Allied Securities. Keith also served as Senior Executive
      for Wachovia Securities, GE Capital, and Prudential Investments.; and has
      built a reputation as a Business Acceleration Executive.
    </p>
    <p>
      Keith is the author of Do Well by Doing Good: The Complete Guide to
      Charitable Remainder Trusts and is a certified planned giving specialist.
      He holds FINRA Series 7, 24, 63, 65 registrations and is a graduate of the
      Securities Industry Institute at University of Pennsylvania’s Wharton
      Business School. Keith was the founder and Chairman of the Wealth Advisor
      Institute, past President of the Institute of Investment Management
      Consultants Association Chapters, and Chairman of the Membership Committee
      for the Investment Management Consulting Association.
    </p>
    <p>
      Keith lives in San
      Diego, CA with his wife, Catherine, and their two sons. He served honorably
      in the US Marine Corps and is a recipient of the Presidential Service Award
      from President Ronald Reagan for his loyal service aboard Marine One.
    </p>
  </>
)

export const DEREK = (
  <>
    <p>
      Derek Bruton is the President of Chalice Network. Derek brings a unique combination of RIA, RIA custodian, and B/D experience to the Members he serves at CFN. A proven strategic and sales leader in the financial services industry for 29 years, Derek has served in executive positions with leading RIA custodians such as Charles Schwab, TD Ameritrade, and LPL Financial. He has led the recruiting of over 10,000 financial advisors in his career and has assisted with the strategy and growth of over 20,000 advisor businesses. Derek frequently speaks at financial services industry events and is often quoted in financial publications.
    </p>
    <p>
      Derek graduated from Stanford University and has completed executive business management coursework at Harvard University. He lives in San Diego, CA with his wife, Helen, and their two girls. Derek played collegiate basketball for Stanford and professional basketball in Japan. He has FINRA licenses 7,8, 24, and 63. He serves on the board of directors of the Invest in Others Charitable Foundation and is involved in other philanthropic efforts in the San Diego community.
    </p>
  </>
)

export const SHAWN = (
  <>
    <p>
      Shawn serves as the Company’s Chief Revenue Officer, and is responsible for driving Revenue throughout the enterprise.  He has 21 years of diverse, progressive and successful experience. Prior to joining Chalice Network, he was CEO, COO and Managing Director of several companies in real estate, venture finance and financial services, including First Allied Securities.  Shawn holds FINRA Series 7, 24, 63, 65 and 79 registrations and is a graduate of the University of Illinois.
    </p>
    <p>
      Shawn honorably served in both the United States Marine Corps as well as the Illinois Army National Guard and received a Letter of Appreciation from then President Bill Clinton as well as a Meritorious Mast from the Commandant of the Marine Corp.
    </p>
    <p>
      Shawn and his wife of 18 years have 2 children, 2 dogs and 2 cats and enjoy family road trips and movie nights.
    </p>
  </>
)

export const TIM = (
  <>
    <p>
      Tim has spent his career in startups.  He cofounded a geolocation mobile application which received funding from Qualcomm Labs. He cofounded a bitcoin wallet and key management software company which makes managing your crypto assets easier than ever. He helped grow and scale Succession Link to 40k+ members. Now he is the CTO of Chalice Network. He also likes to dabble in bioinformatics and has coauthored papers on applying machine learning to detecting Lassa fever biomarkers.  He has a Masters of Computer Science from California State University of San Marcos.
    </p>
    <p>
      Tim lives in Poway, CA with his wife, Chrissy, and their two children and one more on the way. He likes to hike, play guitar and spend time with his family.
    </p>
  </>
)

export const PRICING_DESCRIPTION = `
  Single Point of Entry. Single Sign-On. One Platform. One Price. $100. Take
  your business to the next level. Become a member today 1-800-535-6981.
`
