import React from 'react'
import styled from 'styled-components'
import BannerAd from './BannerAd'

export const BannersContainer = ({ banners = [], placement = '' }) => {
  if (banners.length < 1) return null
  const filteredBanners = banners.filter(
    b => b.display === true && b.is_deleted === 0 && b.placement === placement
  )
  if (filteredBanners.length < 1) return null
  return (
    <BannersWrapper>
      {filteredBanners?.map(banner => {
        return <BannerAd key={banner.id} banner={banner} />
      })}
    </BannersWrapper>
  )
}

const BannersWrapper = styled.div`
  margin: 0 auto;
  max-width: 1170px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
`
