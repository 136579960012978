import React from 'react'
import * as style from './button.module.scss'
import classNames from 'classnames'

export const Button = props => (
  <button {...props}
    className={classNames(
      style.btn, style.btnBlueSolid, props.className, 'primary')}>
    { props.children }
  </button>
)

export const Link = props => (
  <a {...props}
    className={classNames(
      style.btn, style.btnBlueSolid, props.className, 'primary')}>
    { props.children }
  </a>
)
